class slogan_animation{

    constructor(){

        // Changing words
        console.log("run")
        var words,
            currentStep = 1,
            textEl = document.querySelector('.changing_slogan'),
            oldWord = 'Made in Germany',
            standard_timeout = 2000,
            restart_timeout = 4500
        if(document.querySelector('html').getAttribute("lang") == 'de'){
            words = [
                'Made in Germany',
                'Qualität',
                'Innovation',
                'Nachhaltigkeit',
                'Zukunft'
            ]
        }else{
            words = [
                'Made in Germany',
                'Quality',
                'Innovation',
                'Sustainability',
                'Future'
            ]
        }

        // timeout for the start to make sure everything is ready
        function startAnimation(){
            setTimeout(function(){
                if(document.querySelector('section.slider_intro .overlay').classList.contains("loaded")){
                    changeWord()
                }else{
                    startAnimation()
                }
            }, 61)
        }
        startAnimation()

        function changeWord() {
            oldWord = textEl.innerHTML
        
            // check if there is a word atm or not
            if (oldWord.length < 1) {

                if (currentStep !== words.length -1) {
                    currentStep ++
                }else {
                    currentStep = 0
                }
                addNextWord()

            } else {

                if (currentStep !== words.length -1) {
                    setTimeout(deleteWord, standard_timeout)
                }else {
                    setTimeout(deleteWord, restart_timeout)
                }

            }
            
        };

        function deleteWord() {
            var WordLength = oldWord.length,
                currentWord = textEl.innerHTML,
                currentLength = currentWord.length
        
            // The word is deleted so, start adding in the new one
            if (currentLength < 1) {
                changeWord()
                return
            }
        
            // Remove a charachter
            textEl.innerHTML = currentWord.substring(0, currentLength - 1)
        
            setTimeout(deleteWord, 75)
        }

        function addNextWord() {
            var currentWord = textEl.innerHTML,
                currentLength = currentWord.length,
                nextWord = words[currentStep],
                nextWordLength = nextWord.length
        
            if (currentLength === nextWordLength) {
                changeWord()
                return;
            }
        
            // add a charachter
            textEl.innerHTML = nextWord.substring(0, currentLength + 1)

            setTimeout(addNextWord, 75)
        
        }

    }

}
export default slogan_animation
