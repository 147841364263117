import { getStyle } from './helpers'

class breadcrumb {
    constructor( gsap ){

        const parentW = document.querySelector('.breadcrumbbar .pages').offsetWidth

        let childrenW = 0
        const children = document.querySelectorAll('.breadcrumbbar .pages > nav > ul > li')
        children.forEach((child) => {

            let childW = getStyle(child.querySelector('a'),"width")
            let childM = getStyle(child,"margin-left")
            childrenW += childW + childM

        })

        let max_scroll
        if( childrenW + 41 > parentW * 2 ){
            max_scroll = childrenW + 41 - parentW
        }

        if((parentW + 1) < childrenW){

            document.querySelector('.breadcrumbbar .pages > nav > ul').setAttribute('data-current', 0);
            document.querySelector('.breadcrumbbar .scroll_right_nav_button').classList.add("visible")

            document.querySelector('.breadcrumbbar .scroll_right_nav_button').addEventListener('click',(event) =>{

                let current_scroll = document.querySelector('.breadcrumbbar .pages > nav > ul').getAttribute('data-current') * (-1)
                
                if( (current_scroll * (-1)) < max_scroll ){
                
                    document.querySelector('.breadcrumbbar .scroll_left_nav_button').classList.add("visible")

                    let scroll_val = (current_scroll * (-1)) + parentW 
                    if(scroll_val > max_scroll){

                        document.querySelector('.breadcrumbbar .scroll_right_nav_button').classList.remove("visible")
                        scroll_val = max_scroll

                    }

                    document.querySelector('.breadcrumbbar .pages > nav > ul').setAttribute('data-current', scroll_val);
                    document.querySelector('.breadcrumbbar .pages > nav > ul').style.cssText = "transform: translateX(-"+scroll_val+"px);"

                }

            })

            document.querySelector('.breadcrumbbar .scroll_left_nav_button').addEventListener('click',(event) =>{

                let current_scroll = document.querySelector('.breadcrumbbar .pages > nav > ul').getAttribute('data-current') * (-1)

                if( (current_scroll * (-1)) > 0){

                    let scroll_val = (current_scroll * (-1)) - parentW 
                    if(scroll_val < 0){

                        document.querySelector('.breadcrumbbar .scroll_left_nav_button').classList.remove("visible")
                        
                        scroll_val = 0
                        document.querySelector('.breadcrumbbar .pages > nav > ul').setAttribute('data-current', scroll_val);
                        document.querySelector('.breadcrumbbar .pages > nav > ul').style.cssText = "transform: translateX("+scroll_val+"px);"

                    }else{

                        document.querySelector('.breadcrumbbar .scroll_right_nav_button').classList.add("visible")
                        
                        document.querySelector('.breadcrumbbar .pages > nav > ul').setAttribute('data-current', scroll_val);
                        document.querySelector('.breadcrumbbar .pages > nav > ul').style.cssText = "transform: translateX(-"+scroll_val+"px);"

                    }

                }

            })

        } 

    }



}

export default breadcrumb
