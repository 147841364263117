import { scrollto } from './helpers'

class scrolllinks{

    constructor(){
        
        const m1100 = window.matchMedia('(max-width: 1100px)')

        // Top-ScrollDown-Links
        if(document.querySelector("section.start .scroll_down_btn a")){
            document.querySelector("section.start .scroll_down_btn a").addEventListener('click',(event) =>{
                event.preventDefault()
                let distance
                if(document.querySelector("body > div.breadcrumbbar")){
                    distance = document.querySelector("section.start + section").getBoundingClientRect().top + window.scrollY - 60 - 90
                    if (m1100.matches) {
                        distance = document.querySelector("section.start + section").getBoundingClientRect().top + window.scrollY - 60 - (window.innerHeight / 100 * 6.25)
                    }
                }else{
                    distance = document.querySelector("section.start + section").getBoundingClientRect().top + window.scrollY - 90
                    if (m1100.matches) {
                        distance = document.querySelector("section.start + section").getBoundingClientRect().top + window.scrollY - 60 - (window.innerHeight / 100 * 6.25)
                    }
                }
                scrollto(distance)
            })
        }
        if(document.querySelector("section.start.text_intro .column > .textarea:last-child a")){
            document.querySelector("section.start.text_intro .column > .textarea:last-child a").addEventListener('click',(event) =>{
                event.preventDefault()
                let distance = document.querySelector("section.start + section").getBoundingClientRect().top + window.scrollY - 60 - 113 + 8
                if (m1100.matches) {
                    distance = document.querySelector("section.start + section").getBoundingClientRect().top + window.scrollY - 60 - (window.innerHeight / 100 * 5)
                }
                scrollto(distance)
            })
        }

        // Footer-BackToTop-Link
        if(document.querySelector("footer .scrollbutton")){
            document.querySelector("footer .scrollbutton a").addEventListener('click',(event) =>{
                event.preventDefault()
                scrollto(0)
            })
        }

    }
    
    

}

export default scrolllinks
