class link_effects{

    constructor( gsap ){

        const mediaQuery_mobile_nav = window.matchMedia('(max-width: 1100px)')
        
        if (mediaQuery_mobile_nav.matches) {

        }else{

            // navigation
            if( document.querySelector('.breadcrumbbar') && document.querySelector('.mainnav') ){
                document.querySelector('.breadcrumbbar').addEventListener('mouseenter',(event) =>{
                    document.querySelector('.mainnav').classList.add('hover')
                })
                document.querySelector('.breadcrumbbar').addEventListener('mouseleave',(event) =>{
                    document.querySelector('.mainnav').classList.remove('hover')
                })
            }
            if( document.querySelector('.topbar') && document.querySelector('.mainnav') ){
                document.querySelector('.topbar').addEventListener('mouseenter',(event) =>{
                    document.querySelector('.mainnav').classList.add('hover')
                })
                document.querySelector('.topbar').addEventListener('mouseleave',(event) =>{
                    document.querySelector('.mainnav').classList.remove('hover')
                })
            }
            if( document.querySelector('.subnav') && document.querySelector('.mainnav') ){
                document.querySelector('.subnav').addEventListener('mouseenter',(event) =>{
                    document.querySelector('.mainnav').classList.add('hover')
                })
                document.querySelector('.subnav').addEventListener('mouseleave',(event) =>{
                    document.querySelector('.mainnav').classList.remove('hover')
                })
            }
            if( document.querySelector('.has-dropdown') ){
                const dropdown_links = document.querySelectorAll('.has-dropdown')
                for(var i = 0; i < dropdown_links.length; i++){
                    const dropdown_link = dropdown_links[i]
                    dropdown_link.addEventListener('mouseenter',(event) =>{
                        document.querySelector('body').classList.add('fadeout')
                    })
                    dropdown_link.addEventListener('mouseleave',(event) =>{
                        document.querySelector('body').classList.remove('fadeout')
                    })
                }
            }
            if( document.querySelector('.lvl-2') ){
                const lvl2_links = document.querySelectorAll('.lvl-2 > li')
                for(var i = 0; i < lvl2_links.length; i++){
                    const lvl2_link = lvl2_links[i]
                    lvl2_link.querySelector('a').addEventListener('mouseenter',(event) =>{
                        lvl2_link.classList.add('hover')
                    })
                    lvl2_link.querySelector('a').addEventListener('mouseleave',(event) =>{
                        lvl2_link.classList.remove('hover')
                    })
                }
            }
            if( document.querySelector('.languages') ){
                document.querySelector('.languages > a').addEventListener('click',(event) =>{
                    event.preventDefault()
                })
                /* document.querySelector('.languages ul').addEventListener('mouseenter',(event) =>{
                    document.querySelector('.languages > a').classList.add('hover')
                })
                document.querySelector('.languages ul').addEventListener('mouseleave',(event) =>{
                    document.querySelector('.languages > a').classList.remove('hover')
                }) */
            }

        }

        // card-links with image
        if( document.querySelector('.block.card') ){
            const cards = document.querySelectorAll('.block.card')
            for(var i = 0; i < cards.length; i++){
                const card = cards[i]
                if(card.querySelector('.img_container') && card.querySelector('.txt_container .anchor')){
                    card.querySelector('.txt_container .anchor a').addEventListener('mouseenter',(event) =>{
                        card.querySelector('.img_container').classList.add('hover')
                    })
                    card.querySelector('.txt_container .anchor a').addEventListener('mouseleave',(event) =>{
                        card.querySelector('.img_container').classList.remove('hover')
                    })
                }
            }
        }

        // brandteaser
        if( document.querySelector('.brandteaser') ){
            let target = document.querySelector('.brandteaser > div .anchor a').getAttribute('href')
            const logos = document.querySelectorAll('.brandteaser li')
            for(var i = 0; i < logos.length; i++){
                const logo = logos[i]
                logo.addEventListener('mousedown',(event) =>{
                    window.open(target,"_self")
                })
            }
        }

        // slideshows
        if( document.querySelector('.slideshow') ){
            document.querySelector('.slideshow nav a.prev_slide').addEventListener('mouseenter',(event) =>{
                if(!document.querySelector('.slideshow nav a.prev_slide').classList.contains("inactive")){
                    gsap.to(document.querySelector(".slideshow .slider-wrapper"), {
                        duration: .6,
                        x: 48,
                        ease: "Power2.easeInOut"
                    })
                }
            })
            document.querySelector('.slideshow nav a.next_slide').addEventListener('mouseenter',(event) =>{
                if(!document.querySelector('.slideshow nav a.next_slide').classList.contains("inactive")){
                    gsap.to(document.querySelector(".slideshow .slider-wrapper"), {
                        duration: .6,
                        x: -48,
                        ease: "Power2.easeInOut"
                    })
                }
            })
            document.querySelector('.slideshow nav a.prev_slide').addEventListener('mousedown',(event) =>{
                gsap.to(document.querySelector(".slideshow .slider-wrapper"), {
                    duration: .6,
                    x: 0,
                    ease: "Power2.easeInOut"
                })
            })
            document.querySelector('.slideshow nav a.next_slide').addEventListener('mousedown',(event) =>{
                gsap.to(document.querySelector(".slideshow .slider-wrapper"), {
                    duration: .6,
                    x: 0,
                    ease: "Power2.easeInOut"
                })
            })
            document.querySelector('.slideshow nav a.prev_slide').addEventListener('mouseleave',(event) =>{
                gsap.to(document.querySelector(".slideshow .slider-wrapper"), {
                    duration: .6,
                    x: 0,
                    ease: "Power2.easeInOut"
                })
            })
            document.querySelector('.slideshow nav a.next_slide').addEventListener('mouseleave',(event) =>{
                gsap.to(document.querySelector(".slideshow .slider-wrapper"), {
                    duration: .6,
                    x: 0,
                    ease: "Power2.easeInOut"
                })
            })
        }

        if(document.querySelector('a[href$="cookie-einstellungen"]')) {
            document.querySelector('a[href$="cookie-einstellungen"]').addEventListener('click',(event) =>{
                event.preventDefault() 
                klaro.show()
            })
        }

    }

}

export default link_effects
