// get the index of element (el)
export function index(el){
    return [...el.parentElement.children].indexOf(el)
}

// check if element is last in list
export function isLastListItem(li) {
    const ul = li.parentNode
    const lis = ul.getElementsByTagName("li")
    return li === lis[lis.length - 1]
}

// get value from CSS (ele, rule)
export function getStyle(oElm, strCssRule){
    var strValue = ""
    if(document.defaultView && document.defaultView.getComputedStyle){
        strValue = document.defaultView.getComputedStyle(oElm, "").getPropertyValue(strCssRule)
    }
    else if(oElm.currentStyle){
        strCssRule = strCssRule.replace(/\-(\w)/g, function (strMatch, p1){
            return p1.toUpperCase()
        })
        strValue = oElm.currentStyle[strCssRule]
    }
    strValue = strValue.replace('px','')
    strValue = strValue.replace('%','')
    strValue = strValue.replace('em','')
    strValue = strValue.replace('rem','')
    strValue = strValue.replace('vh','')
    strValue = strValue.replace('vw','')
    return Number(strValue)
}

// check if element is in viewport
export function isInViewport(el){
    let rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

// scrollTo Links
export function scrollto(distance){
    document.scrollingElement.scroll({
        top: distance,
        behavior: 'smooth'
    })
    // history.pushState(null, null, `/#${targetID}`)
}

// hide contact popup
export function unstick_contact_popup(gsap){
    document.querySelector('footer .contactinfo .card').classList.remove("sticky")

    let style_text = ""

    gsap.to(document.querySelector('footer .contactinfo .card'), {
        duration: .6,
        y: '100%',
        ease: "Power2.easeInOut",
        onComplete: function(){
            document.querySelector('footer .contactinfo .card').style.cssText = style_text
        }
    })

}
