import { unstick_contact_popup } from './helpers'

class scrolling{

    constructor( gsap, ScrollTrigger ){

        const scrollLast = 0
        this.scrollLast = scrollLast

        const winH = window.innerHeight
        this.winH = winH

        const canvasH = document.querySelector("#cc").offsetHeight
        this.canvasH = canvasH

        const mainNavH = document.querySelector(".mainnav").offsetHeight
        this.mainNavH = mainNavH

        this.updateSizes()

        this.initialCheck( gsap )
        document.addEventListener('scroll',() => this.draw( gsap ),{passive: true}) 
        
        window.addEventListener('load',() =>{ 
            this.updateSizes() 
            this.setScrollTriggers( ScrollTrigger )
        })
        
        window.addEventListener('resize',() => this.updateSizes(),{passive: true})  

    }

    scrolling(scrollTop, gsap){

        const m900 = window.matchMedia('(min-width: 901px)')
        if (m900.matches) {
            // clip the canvas (hence the yarns in the background)
            gsap.to(document.querySelector(".background .mask"), {
                duration: 1.16,
                y: parseInt(((scrolling.winH / (scrolling.canvasH)) * scrollTop) + scrolling.mainNavH + (scrolling.mainNavH / 2)),
                ease: "Power2.easeOut"
            })
        }

        const m1100 = window.matchMedia('(max-width: 1100px)')
        const m1280 = window.matchMedia('(max-width: 1280px)')
        
        if (m1100.matches) {

            // Logo / Navigation
            if(scrollTop <= 1){
                document.querySelector("body > .topbar").classList.remove('collapsed')
            }else{
                document.querySelector("body > .topbar").classList.add('collapsed')
            }

        }else{

            // Logo / Navigation
            if(scrollTop <= 1){

                // Logo + Main Nav
                document.querySelector('.mainlogo > div > a').style.cssText = null
                document.querySelector('.mainlogo > div > a > div > div:first-of-type').style.cssText = null
                document.querySelector('.mainlogo > div > a > div > div:last-of-type').style.cssText = null
                document.querySelector('.mainnav ul.lvl-1').style.cssText = null

                if (m1280.matches) {
                    // SubNav
                    document.querySelector('.subnav').classList.remove('reduced')
                    document.querySelector('.subnav .secondnav').style.cssText = null
                    document.querySelector('.subnav .socialmedia').style.cssText = null
                    document.querySelector('.subnav .languages').style.cssText = null
                }

            }else{

                // Logo + Main Nav

                let logoMove = ((60 - 44) / 2) - 20
                let move = scrollTop * (logoMove / 60)
                if(move < -12){
                    move = (-12)
                }else if(move > 0){
                    move = 0
                }
                let cssMove = 'margin-top:'+move+'px;'

                let logoScale = 80 - 44
                let size = 80 - (scrollTop * (logoScale / 60))
                if(size < 44){
                    size = 44
                }else if(size > 80){
                    size = 80
                }
                let cssSize = 'height:'+size+'px;'

                let scale = parseInt(document.querySelector('.mainlogo > div > a > div > div:first-of-type svg').clientWidth) / parseInt(document.querySelector('.mainlogo > div > a > div > div:first-of-type').clientWidth)
                let cssScale = 'transform: scale('+scale+');'

                let adapt = parseInt(document.querySelector('.mainlogo > div > a > div > div:first-of-type svg').clientWidth) - parseInt(document.querySelector('.mainlogo > div > a > div > div:first-of-type').clientWidth)
                let cssAdapt = 'transform: translateX('+adapt+'px);'
                    
                document.querySelector('.mainlogo > div > a').style.cssText = cssMove
                document.querySelector('.mainlogo > div > a > div > div:first-of-type').style.cssText = cssSize
                document.querySelector('.mainlogo > div > a > div > div:last-of-type').style.cssText = cssScale
                document.querySelector('.mainnav ul.lvl-1').style.cssText = cssAdapt

                if(scrollTop >= 20){
                    document.querySelector('.mainlogo > div > a > div > div:last-of-type').classList.add("hidden")
                }else{
                    document.querySelector('.mainlogo > div > a > div > div:last-of-type').classList.remove("hidden")
                }

                if (m1280.matches) {

                    // SubNav
                    var sm_style = document.querySelector('.subnav .socialmedia').currentStyle || window.getComputedStyle(document.querySelector('.subnav .socialmedia'))
                    var sm_margin_left = parseInt(sm_style.marginLeft)
                    var lang_style = document.querySelector('.subnav .languages').currentStyle || window.getComputedStyle(document.querySelector('.subnav .languages'))
                    var lang_margin_left = parseInt(lang_style.marginLeft)
            
                    document.querySelector('.subnav').classList.add('reduced')
                    document.querySelector('.subnav .secondnav').style.cssText = 'transform: translateX('+(document.querySelector('.subnav .socialmedia').offsetWidth + sm_margin_left + document.querySelector('.subnav .languages').offsetWidth + lang_margin_left)+'px);'

                }

            }
                
        }

        // manual ScrollTriggers
        if(document.querySelector(".start")){
            
            if(scrollTop <= 0){
                // top

                // Logo / Navigation
                document.querySelector('.mainlogo > div > a > div > div:first-of-type').style.cssText = null

                // parallax Intro
                if(document.querySelector(".start.slider_intro") || document.querySelector(".start.media_intro")){
                    gsap.to(document.querySelector(".start .content_container"), {
                        duration: .161,
                        y: 0,
                        ease: "Power2.easeOut"
                    })
                }
                if(document.querySelector(".start > .overlay")){
                    document.querySelector(".start > .overlay").style.cssText = "margin-top:0px"
                }

            }else if(scrollTop <= document.querySelector(".start").offsetHeight){
                // start
                
                // parallax Intro
                if(document.querySelector(".start.slider_intro") || document.querySelector(".start.media_intro")){
                    gsap.to(document.querySelector(".start .content_container"), {
                        duration: .161,
                        y: (parseInt(scrollTop) * -0.618),
                        ease: "Power2.easeOut"
                    })
                }
                if(document.querySelector(".start > .overlay")){
                    document.querySelector(".start > .overlay").style.cssText = "margin-top:"+(parseInt(scrollTop) * 0.618) +"px"
                }

                // ScrollDown-Button
                if(document.querySelector(".start .scroll_down_btn")){
                    if(scrollTop > (document.querySelector(".start").clientHeight - scrolling.winH + 60)){
                        document.querySelector(".start .scroll_down_btn").classList.add('unpinned')
                    }else{
                        document.querySelector(".start .scroll_down_btn").classList.remove('unpinned')
                    }
                    if(scrollTop > document.querySelector(".start").offsetHeight){
                        document.querySelector(".start .scroll_down_btn").classList.add("paused")
                    }else{
                        document.querySelector(".start .scroll_down_btn").classList.remove("paused")
                    }
                }

                // Start-Video
                if(document.querySelector(".start video")){
                    document.querySelector(".start video").play()
                    document.querySelector(".start video").classList.remove("paused")
                }

            }else{
                // rest

                // Start-Video
                if(document.querySelector(".start video")){
                    document.querySelector(".start video").classList.add("paused")
                    document.querySelector(".start video").pause()
                }

            }

        }

    }

    setScrollTriggers( ScrollTrigger ){
        
        // GSAP ScrollTriggers

        // image preloading
        document.querySelectorAll('main section img.preload').forEach((img) => {

            if(img.parentNode.getAttribute('data-ratio') != "1/1"){
                // img.style.cssText = "height:"+img.parentNode.offsetHeight+"px;"
            }

            ScrollTrigger.create({
                trigger: img.parentElement,
                start: "top " + (img.parentElement.offsetTop + window.innerHeight + 25),
                onEnter: function(){
                    if(img.classList.contains("preload")){
                        img.setAttribute('src', img.getAttribute('data-src'))
                        img.classList.remove("preload")
                    }
                }
            })

        })

        // standard image fadeIn
        document.querySelectorAll('main section:not(.start)').forEach((section) => {

            if(section.querySelector('.col_3_wrapper') || section.querySelector('.col_2_wrapper')){

                let delay = 100
                section.querySelectorAll('.block:not(.kpis):not(.brandteaser):not(.keyfacts):not(.colorcard):not(.image_slider):not(.image_masonry) figure img').forEach((img) => {

                    let figure = img.parentElement
                    let container = figure.parentElement
                    let block = container.parentElement.parentElement

                    let column = block.parentElement
                    if(container.classList.contains("column")){ column = container }
                    else if(block.classList.contains("column")){ column = block }

                    if(window.scrollY + figure.getBoundingClientRect().top > scrolling.winH){

                        figure.setAttribute('data-scrollTrigger', true)
                        figure.setAttribute('data-triggerDelay', delay)

                        ScrollTrigger.create({
                            trigger: figure.parentElement,
                            start: "top " + (figure.parentElement.offsetTop + scrolling.winH - figure.getAttribute('data-triggerDelay')), // element viewport
                            onEnter: function(){
            
                                figure.setAttribute('data-scrollTrigger', false)
                                figure.setAttribute('data-triggerTimeout', 'set')

                                // timer for following elements
                                let nextSibling = column.nextSibling
                                let timeout = 200
                                
                                while (nextSibling) {
                                    let targetSibling = nextSibling

                                    if( targetSibling.nodeName === "DIV" && targetSibling.querySelector('figure') ){
                                        if ( column.querySelector('figure').offsetTop == targetSibling.querySelector('figure').offsetTop && !targetSibling.querySelector('figure').hasAttribute('data-triggerTimeout') ) {
                                                
                                            // set timeout
                                            setTimeout(function(){
                                                if(targetSibling.querySelector('figure').getAttribute('data-scrollTrigger') === 'true'){
                                                    targetSibling.querySelector('figure').setAttribute('data-scrollTrigger', false)
                                                } 
                                            },timeout)
                                            targetSibling.querySelector('figure').setAttribute('data-triggerTimeout', 'set')
                                            timeout = timeout + 200
    
                                        }
                                    }

                                    nextSibling = nextSibling.nextSibling
                                }

                            }

                        })

                    }
                    
                    delay = delay + 100

                })

            }else{

                // single column -> just load image(s)
                let delay = 100
                section.querySelectorAll('main .block:not(.kpis):not(.brandteaser):not(.keyfacts):not(.colorcard):not(.image_slider):not(.image_masonry) figure img').forEach((img) => {
                    let figure = img.parentElement
                    if(window.scrollY + figure.getBoundingClientRect().top > scrolling.winH){
                        figure.setAttribute('data-scrollTrigger', true)
                        figure.setAttribute('data-triggerDelay', delay)
                        ScrollTrigger.create({
                            trigger: figure.parentElement,
                            start: "top " + (figure.parentElement.offsetTop + scrolling.winH - figure.getAttribute('data-triggerDelay')), // element viewport
                            onEnter: function(){
                                figure.setAttribute('data-scrollTrigger', false)
                            }
                        })
                        delay = delay + 100
                    }
                })

            }

        })
        
        // keyfacts (on homepage, Nachhaltigkeit and Innovationswerkstatt)
        if( document.querySelector('main .block.live_tiles') ){

            //
            document.querySelectorAll('main .block.live_tiles *[data-scrollTrigger="true"]').forEach((el) => {
                // basic fade in
                ScrollTrigger.create({
                    trigger: el,
                    start: "top " + (el.offsetTop + scrolling.winH - el.getAttribute('data-triggerDelay')),
                    onEnter: function(){
                        el.setAttribute('data-scrollTrigger', false)
                        el.setAttribute('data-triggerTimeout', 'set')
                        // timer for following elements
                        let trigger_group = el.getAttribute('data-triggergroup')
                        let timeout = 200
                        document.querySelectorAll('*[data-triggergroup="'+trigger_group+'"]').forEach((sib) => {
                            if (el.offsetTop == sib.offsetTop && !sib.hasAttribute('data-triggerTimeout')) {
                                // set timeout
                                setTimeout(function(){
                                    if(sib.getAttribute('data-scrollTrigger') === 'true'){
                                        sib.setAttribute('data-scrollTrigger', false)
                                    }
                                },timeout)
                                sib.setAttribute('data-triggerTimeout', 'set')
                                timeout = timeout + 200
                            }
                        })
                    }
                })
                if(el.classList.contains("info_chunk")){
                    // trigger count
                    ScrollTrigger.create({
                        trigger: el.querySelector("p:last-of-type"),
                        start: "top " + (el.querySelector("p:last-of-type").offsetTop + scrolling.winH - el.getAttribute('data-triggerDelay') / 2),
                        onEnter: function(){
                            
                            const start_val = parseInt(el.querySelector(".count").getAttribute('data-start'))
                            const target_val = parseInt(el.querySelector(".count").getAttribute('data-target'))
                            const direction = el.querySelector(".count").getAttribute('data-direction')
                            
                            let current_val = start_val
                            
                            let distance_val
                            if(direction == 'down'){ distance_val = start_val - target_val }
                            else{ distance_val = target_val - start_val }

                            let speed
                            let speed_up
                            speed = distance_val * 1
                            speed_up = distance_val / 50

                            let index = 0
                            const timer = function(){
                                index ++
                                speed = speed - (speed_up * index)
                                if(direction == 'down'){ current_val = current_val - 1 }
                                else{ current_val = current_val + 1 }
                                el.querySelector(".count").innerHTML = current_val
                                if(current_val == target_val){ return false }
                                setTimeout(timer, speed)
                            }

                            if(el.getAttribute('data-status') == "true"){
                                timer()
                                el.setAttribute('data-status', false)
                            }

                        }
                    })
                }
            })

        }
        
        // brandteaser on homepage
        if( document.querySelector('main .block.brandteaser') ){
            document.querySelectorAll('main .block.brandteaser li').forEach((el) => {
                ScrollTrigger.create({
                    trigger: el.querySelector('figure'),
                    start: "top " + (el.querySelector('figure').offsetTop + scrolling.winH - el.querySelector('figure').getAttribute('data-triggerDelay')),
                    onEnter: function(){
                        if(el.querySelector('figure').getAttribute('data-scrollTrigger') === 'true'){
                            el.querySelector('figure').setAttribute('data-scrollTrigger', false)
                            el.querySelector('figure').setAttribute('data-triggerTimeout', 'set')
                            // timer for following elements
                            let nextSibling = el.nextSibling
                            let timeout = 200
                            
                            while (nextSibling) {
                                let targetSibling = nextSibling
                                if (targetSibling.nodeName === "LI" && el.querySelector('figure').offsetTop == targetSibling.querySelector('figure').offsetTop && !targetSibling.querySelector('figure').hasAttribute('data-triggerTimeout')) {
                                    // set timeout
                                    setTimeout(function(){
                                        if(targetSibling.querySelector('figure').getAttribute('data-scrollTrigger') === 'true'){
                                            targetSibling.querySelector('figure').setAttribute('data-scrollTrigger', false)
                                        } 
                                    },timeout)
                                    targetSibling.querySelector('figure').setAttribute('data-triggerTimeout', 'set')
                                    timeout = timeout + 200
                                }
                                nextSibling = nextSibling.nextSibling
                            }
                            
                        }
                    }
                })
            })
        }
        
        // newsticker on homepage
        if( document.querySelector('main .block.newsticker') ){
            document.querySelectorAll('main .block.newsticker ul li').forEach((el) => {
                ScrollTrigger.create({
                    trigger: el,
                    start: "top " + (el.offsetTop + scrolling.winH - el.getAttribute('data-triggerDelay')),
                    onEnter: function(){
                        el.setAttribute('data-scrollTrigger', false)
                        el.setAttribute('data-triggerTimeout', 'set')
                        // timer for following elements
                        let nextSibling = el.nextSibling
                        let timeout = 200

                        while (nextSibling) {
                            let targetSibling = nextSibling
                            if (targetSibling.nodeName === "LI" && !targetSibling.hasAttribute('data-triggerTimeout')) {
                                // set timeout
                                setTimeout(function(){
                                    if(targetSibling.getAttribute('data-scrollTrigger') === 'true'){
                                        targetSibling.setAttribute('data-scrollTrigger', false)
                                    } 
                                },timeout)
                                targetSibling.setAttribute('data-triggerTimeout', 'set')
                                timeout = timeout + 200
                            }
                            nextSibling = nextSibling.nextSibling
                        }
                        
                    }
                })
            })
        }
        
        // color cards
        if( document.querySelector('main.colorcards') ){
            document.querySelectorAll('main.colorcards section:not(.start)').forEach((section) => {
                let delay = 100
                section.querySelectorAll('.colorcard').forEach((colorcard) => {
                    if(window.scrollY + colorcard.getBoundingClientRect().top > scrolling.winH){
                        colorcard.setAttribute('data-scrollTrigger', true)
                        colorcard.setAttribute('data-triggerDelay', delay)
                        ScrollTrigger.create({
                            trigger: colorcard,
                            start: "top " + (colorcard.offsetTop + scrolling.winH - colorcard.getAttribute('data-triggerDelay')), // element viewport
                            onEnter: function(){

                                colorcard.setAttribute('data-scrollTrigger', false)

                            }
                        })
                    }
                    delay = delay + 100
                })
            })
        }

    }

    updateSizes(){
        scrolling.winH = window.innerHeight
        scrolling.canvasH = document.querySelector("#cc").offsetHeight
        scrolling.mainNavH = document.querySelector(".mainnav").offsetHeight
    }

    initialCheck( gsap ){
        const scrollTop = document.scrollingElement.scrollTop
        this.scrolling( scrollTop, gsap )
        scrolling.scrollLast = scrollTop 
    }

    draw( gsap ){ 
        const scrollTop = document.scrollingElement.scrollTop
        this.scrolling( scrollTop, gsap )
        if(document.querySelector('footer .contactinfo .card').classList.contains("sticky")){
            unstick_contact_popup( gsap )
        }
        scrolling.scrollLast = scrollTop 
    }

}

export default scrolling
