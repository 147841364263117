import './polyfills/scrollingElement' // fix for scrolling
import './polyfills/forEach' // IE fix forEach-function
import vh_fix from './polyfills/vh_fix' // 100vh fix for smartphones
import './polyfills/Promise'
import './polyfills/details-element-polyfill'

import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

import PhotoSwipeLightbox from 'photoswipe/lightbox' // lightbox (photoswipe)

import breadcrumb from './components/breadcrumb'
import navigation_mobile from './components/mobile_nav'

import scrolling from './components/scrolling' // scrolling
import link_effects from './components/link_effects' // additional effects for various links
import scrolllinks from './components/scrolllinks' // scrolllinks
import paperjs from './components/paper' // paths in the background

import slogan_animation from './components/slogan_new' // scrolling
// import contact_popup from './components/contact_popup' // contact popup
import slideshows from './components/slideshows' // swiper

let mq520 = window.matchMedia('screen and (max-width: 760px)')
let mq760 = window.matchMedia('screen and (max-width: 760px)')
let mq1100 = window.matchMedia('screen and (max-width: 1100px)')
let mobile_init = false

document.addEventListener('DOMContentLoaded',() =>{

  new vh_fix()

  new scrolling( gsap, ScrollTrigger )
  new link_effects( gsap )

  if(mq1100.matches){ 
    
    const wrapperDiv = document.createElement('div')
    wrapperDiv.classList.add("mobile_navigation")
    const innerDiv = document.createElement('div')
    innerDiv.appendChild(document.querySelector('.mainnav'))
    innerDiv.appendChild(document.querySelector('.subnav'))
    wrapperDiv.appendChild(innerDiv)
    const parentElement = document.querySelector('body')
    const insertBeforeElement = document.querySelector('.topbar + *')
    parentElement.insertBefore(wrapperDiv, insertBeforeElement)

    new navigation_mobile()
    mobile_init = true
    
  }

  new scrolllinks()
  new slideshows()

  // use the flickr image justigy layout (https://flickr.github.io/justified-layout/)
  if(document.querySelector('.image_masonry')){
    document.querySelectorAll('.image_masonry').forEach((imagemasonry) => {

      let ratio_array = []
      imagemasonry.querySelectorAll('.image').forEach((image) => {
        ratio_array.push( Number(image.getAttribute('data-justify-ratio')) )
      })

      var img_style = document.querySelector('.image_masonry .image:first-of-type').currentStyle || window.getComputedStyle(document.querySelector('.image_masonry .image:first-of-type'))
      var img_margin_left = parseInt(img_style.marginLeft)

      var layoutGeometry = require('justified-layout')(ratio_array,{
        containerWidth: (imagemasonry.offsetWidth + (img_margin_left * 2)),
        boxSpacing: {
          horizontal: (img_margin_left * 2),
          vertical: 0
        },
        targetRowHeight: imagemasonry.querySelector('.pswp-gallery').getAttribute('data-target-row-height'),
        targetRowHeightTolerance: imagemasonry.querySelector('.pswp-gallery').getAttribute('data-target-row-height-tolerance')
      })

      imagemasonry.querySelectorAll('.image').forEach((image, index) => {
        image.querySelector('figure > img').style.cssText = 'height:'+layoutGeometry.boxes[index].height+'px;'
      })

    })
  }

  // use the pswp Lightbox (https://photoswipe.com/)
  if(document.querySelector('.pswp-gallery')){

    let padding = window.innerWidth / 100 * (3.125 * 1.25)
    if(mq1100.matches){ padding = window.innerWidth / 100 * 3.125 }
    else if(mq760.matches){ padding = window.innerWidth / 100 * 2.5 }
    else if(mq520.matches){ padding = window.innerWidth / 100 * 1.25 }

    document.querySelectorAll('.pswp-gallery').forEach((pswpgallery) => {
  
      const lightbox = new PhotoSwipeLightbox({
        gallery: pswpgallery,
        children: 'a',
        pswpModule: () => import('photoswipe'),
        padding: { top: padding, bottom: padding, left: padding, right: padding }
      })
  
      lightbox.init()
  
    })
  }

})

window.addEventListener('load', (event) => {

  if( document.querySelector('.breadcrumbbar .pages') ){
    new breadcrumb( gsap )
  }

  new paperjs( gsap, ScrollTrigger )

  if(document.querySelector(".start > .overlay")){
    setTimeout(function(){
      document.querySelector(".start > .overlay").classList.add("loaded")
      new slogan_animation()
    }, 61)
  }
  
})
